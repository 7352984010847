// extracted by mini-css-extract-plugin
export var benefitsBlock = "exampleWithLaptop-module--benefitsBlock--gt3zY";
export var benefitsItem = "exampleWithLaptop-module--benefitsItem--ZlNUq";
export var border = "exampleWithLaptop-module--border--IaqPW";
export var exampleBlock = "exampleWithLaptop-module--exampleBlock--tzoB5";
export var halvaBgLogo = "exampleWithLaptop-module--halvaBgLogo--P3dqR";
export var monthlyPaymentBlock = "exampleWithLaptop-module--monthlyPaymentBlock--Zf24a";
export var monthlyPaymentValue = "exampleWithLaptop-module--monthlyPaymentValue--pMr5i";
export var monthlyText = "exampleWithLaptop-module--monthlyText--eUEW7";
export var termAndTotalBlock = "exampleWithLaptop-module--termAndTotalBlock--O0uqf";
export var termFirstText = "exampleWithLaptop-module--termFirstText--YjjGM";
export var termSecondText = "exampleWithLaptop-module--termSecondText--hdrE-";
export var totalAmountText = "exampleWithLaptop-module--totalAmountText--YafG3";
export var totalAmountValue = "exampleWithLaptop-module--totalAmountValue--IUbai";